import React from "react";

const ContentComponent = ({ data }) => {
  return (
    <div
      className="wp-block wp-block--paragraph wp-block--float-image mt-4 content-container content-component"
      dangerouslySetInnerHTML={{ __html: data.childrenMarkdownRemark[0].html }}
    />
  );
};

export default ContentComponent;
